import { PosSystem, PosSystemCollection } from 'models/entities/pos-system';

import { Condition } from './models/condition';

class Model {

  readonly condition: Condition;
  readonly collection?: PosSystemCollection;
  readonly list?: PosSystem[];

  constructor() {
    this.condition = new Condition();
  }

  async read(): Promise<this> {
    const collection = await PosSystemCollection.read();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  build(): PosSystem {
    return new PosSystem();
  }

  add(system: PosSystem): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.add(system));
  }

  replace(system: PosSystem): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.replace(system));
  }

  remove(system: PosSystem): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.remove(system));
  }

  private apply(collection: PosSystemCollection): this {
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

}

export { Model };