import { PosSystem } from 'models/entities/pos-system';

class Condition {

  constructor() {
  }

  filter(all: PosSystem[]): PosSystem[] {
    return all.filter(it => this.check(it));
  }

  private check(system: PosSystem): boolean {
    return true;
  }

}

export { Condition };